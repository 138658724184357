body {
    font-family: 'Lustria', serif;
    font-size: 16px;
    line-height: 1.8;
}
.bg-white{
    background: #fff !important;
}
.contact-form .text, .contact-form .textarea {
    margin-top: 30px;
    margin-bottom: 4px;
}
.responsive-container img{
    max-width: 100% !important;
}
.responsive-container{
    text-align: left;
}
.responsive-container ul{
    list-style:circle;
    margin-left: 20px;
}
.industries-work-flow.about .work-flow-item {
    padding:0;
}
.industries-work-flow .work-flow-item .work-flow-icon span {
    font-size: 20px;
    line-height: 36px;
    padding-top: 3px;
}
.industries-tab .nav-tabs li a img {
    position: absolute;
    width: 26px;
    left:50%;
    margin-left:-13px;
    top:12px;
}
.industries-tab .nav-tabs li a img.white {
    display: none;
}
.industries-tab .nav-tabs li.active a img {
    display: none;
}
.industries-tab .nav-tabs li.active a img.white {
    display: block;
}
.cta-contact h3 strong{
    color: #e7dd2d;
}
.cta-contact p{
    border-left:3px solid #e7dd2d;
}
.projects-article.career {
    background: #fff;
    box-shadow: 0 4px 32px 0 rgba(10,14,29,.02), 0 8px 64px 0 rgba(10,14,29,.08);
    border-radius: 50px 30px;
}
.page-title ol.breadcrumb li a {
    color: #e7dd2d;
}
.page-title ol.breadcrumb li a:hover {
    color: #fef9ff;
}
.page-title ol.breadcrumb li.active {
    color: #ddd9b6;
}
.career .project-content{
    padding: 20px 36px;
}
.about-bg-img{
    background-image: url(../images/about-bg.jpg);
    background-repeat: repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 0 50%;
}
.testimonial-body{
    padding-left:120px;
}
.testimonial-item img{
    width: 100px;
}
.projects-article .project-content ul{
    list-style: initial;
    margin: initial;
    padding: 0 0 0 40px;
}
.projects-article .project-content li {
    display: list-item;
    padding-left:15px;
}

.projects-article .project-content ul.meta-data  {
    list-style: none;
    margin:0 0 10px 0   ;
    padding:0;
}

.projects-article .project-content .meta-data li {
    padding-left:0;
}

.nav-tabs.about-page li {
    width:50%;
    float:left !important;
}
.portfolio-container .thumb-img .overlay{
    background: rgba(255,255,255,0.5);
}
.portfolio-container .btn-center a {
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
}
.portfolio-container .btn-center a:hover {
    border-bottom: 3px solid #e7dd2d;
    border-left: 3px solid #e7dd2d;
    background: #000;
}
.service-read{
    text-align: left;
}

.gmap-canvas {
    height: 350px;
}
.error-heading {
    height: 200px;
    background-image: none;
}
.pricing2-box span.pricing2-price {
    font-size: 20px;
    line-height: 45px;
}
.lab {
    position: relative;
    margin: 40px auto 0;
    width: 42px;
    height: 140px;
}

.lab img {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.lab i {
    z-index: 2;
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 16px;
    opacity: 0;
    animation: bubble1 linear 3s infinite;
}

.lab i:nth-child(1) {
    animation-name: bubble1;
}

.lab i:nth-child(2) {
    animation-delay: .7s;
    animation-name: bubble2;
}

.lab i:nth-child(3) {
    animation-delay: 1.5s;
    animation-name: bubble1;
}

.lab i:nth-child(4) {
    animation-delay: 2.3s;
    animation-name: bubble2;
}

.owl-brand .item {
    padding:5px;
}
.owl-brand img {
    -webkit-box-shadow: 0 4px 2px -2px rgba(0,0,0,0.4) !important;
    -moz-box-shadow: 0 4px 2px -2px rgba(0,0,0,0.4) !important;
    box-shadow: 0 4px 2px -2px rgba(0,0,0,0.4) !important;
    border:solid 1px #ccc;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 30px;
}

.whatsapp{
    position:fixed;
    width:60px;
    height:60px;
    bottom: 40px;
    right: 20px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
    .fa-whatsapp {
        margin-top:16px;
    }

    &.second {
        bottom: 110px;
    }
}

.i-img {
    border-radius: 50px 30px;
    background: #fff;
    box-shadow: 0 4px 32px 0 rgba(10,14,29,.02), 0 8px 64px 0 rgba(10,14,29,.08);
}

@keyframes bubble1{
    0%{bottom:1px; left:21px; transform:scale(.1); opacity:1; background:#fff;}
    10%{bottom:5px; left:17px; transform:scale(.2); opacity:1; background:#fff; }
    20%{bottom:10px; left:21px; transform:scale(.2); opacity:1; background:#fff;}
    30%{bottom:15px; left:15px; transform:scale(.3);opacity:1; background:#fff;}
    40%{bottom:19px; left:21px; transform:scale(.4); opacity:1; background:#fff; }
    49%{bottom:19px; left:17px; transform:scale(.4); opacity:1; background:#fff; }
    50%{bottom:19px; left:17px; transform:scale(.5); opacity:1; background:#ff8ab8; }
    60%{bottom:55px; left:21x; transform:scale(.6); }
    70%{bottom:60px; left:16px; transform:scale(.7); }
    80%{bottom:65px; left:21px; transform:scale(.8);}
    90%{bottom:70px; left:17px; transform:scale(1); }
    99%{bottom:73px; left:21px; transform:scale(1); opacity:.5;}
    100%{bottom:73px; left:21px; transform:scale(5); opacity:0; background:#ff8ab8;}
}

@keyframes bubble2{
    0%{bottom:1px; left:13px; transform:scale(.1); opacity:1; background:#fff;}
    10%{bottom:5px; left:18px; transform:scale(.2); opacity:1; background:#fff; }
    20%{bottom:10px; left:13px; transform:scale(.2); opacity:1; background:#fff;}
    30%{bottom:15px; left:18px; transform:scale(.3);opacity:1; background:#fff;}
    40%{bottom:19px; left:13px; transform:scale(.4); opacity:1; background:#fff; }
    49%{bottom:19px; left:18px; transform:scale(.4); opacity:1; background:#fff;}
    50%{bottom:19px; left:18px; transform:scale(.5); opacity:1; background:#61d3ff;}
    60%{bottom:55px; left:13px; transform:scale(.6); }
    70%{bottom:60px; left:18px; transform:scale(.7); }
    80%{bottom:65px; left:13px; transform:scale(.8);}
    90%{bottom:70px; left:18px; transform:scale(1); }
    99%{bottom:73px; left:13px; transform:scale(1); opacity:.5; }
    100%{bottom:73px; left:13px; transform:scale(5); opacity:0; background:#61d3ff;}
}

@media (min-width: 768px) {
    .nav-tabs.about-page li {
        width: 25%;
    }
/*
    .service-info a.service-btn,
    .service-horizontal-tabs .nav{
        width: 30%;
    }
    .service-horizontal-tabs .tab-content{
        width: 66%;
    }*/
}
@media (max-width: 992px) {
    .logo-img img {
        max-width: 200px;
    }
}
@media (max-width: 767px) {

    .logo-img img {
        max-width: 280px;
        margin-top: 5px;
    }
}