/*
-------------------------------
 TABLE OF CONTENTS:
---------------------------------
1. Global CSS
2. Topbar
3. Logo
4. Main Menu
5. Slider
6. Page Title
7. Features
8. Counting
9. Industries About
10. Call to Action
11. Projects
12. Circle Percentage
13. Services
14. Call To Action Contact
15. Team
16. Price
17. Clients
18. Testimonial
19. Bottom CTA
20. FAQS
21. Industry Page
22. Coming Soon
23. 404 Page
24. Portfolio
25. News
26. Blog
27. Sidebar
28. Contact Us
29. Footer
30. Copyright
-----------------------------*/
/* ---------------------------
    1. Global CSS
---------------------------- */

html,
body {
    height: 100%;
}
body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 24px;

    background: url(../images/bg.png);
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
a {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
a:hover {
    text-decoration: none;
}
h3,
h4 {
    font-family: 'Lustria', serif;
    font-weight: 600;
}
.tg-btn {
    padding: 0;
    border: 0;
    border-bottom: 2px solid #2b3752;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 13px;
    background: none;
    color: #444;
    margin-top: 0;
    margin-bottom: 8px;
    letter-spacing: 1px;
    -moz-transition: all linear .3s;
    -o-transition: all linear .3s;
    -webkit-transition: all linear .3s;
    transition: all linear .3s;
}
.tg-btn:hover {
    padding-right: 50px !important;
}
.btn {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    color: #fff;
    border-color: #444;
    background: #444;
    padding: 5px 15px;
    border-radius: 0 20px 0 20px;
    border: none;
    border-bottom: 3px solid #d6d6d6;
    -moz-transition: all linear .3s;
    -o-transition: all linear .3s;
    -webkit-transition: all linear .3s;
    transition: all linear .3s;
}
.btn:hover {
    color: #fff;
    border-color: #2b3752;
    background: #2b3752;
}
.cta-btn {
    float: right;
    margin-right: 5px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    color: #444;
    border-color: #fff;
    background: #fff;
    padding: 6px 25px;
    border-radius: 0 20px 0 20px;
    border-bottom: 3px solid #d6d6d6;
}
.cta-btn:hover {
    color: #fff;
    border-color: #2b3752;
    background: #2b3752;
}
.hover-black:hover {
    background: #444;
    color: #fff;
}
.section-title {
    position: relative;
    padding-left: 10px;
    margin-bottom: 30px;
}
.section-title:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 55px;
    background: #8e8d92;
    top: 0;
    left: 0;
}
.section-title strong {
    color: #8e8d92;
    font-weight: 600;
}
.section-title h3 {
    text-transform: uppercase;
    margin-top: 0px;
    line-height: 30px;
}
.section-bg-img {
    margin: 0;
    padding: 50px 0;
    color: #ffffff;
    background-image: url(../images/section-bg.jpg);
    background-repeat: repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 0 50%;
}
.section-bg-color {
    background: rgba(107, 107, 107, 0.6);
    padding: 50px 0;
}
.section-padding {
    margin: 0px 0px;
    padding: 70px 0 70px 0px;
}
.wrapper {
    overflow: hidden;
}


/* ---------------------------
    2. Topbar
---------------------------- */
.topbar {
    position: relative;
    overflow: hidden;
}
.topbar:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 30px solid #2b3752;
    border-right: 4000px solid #2b3752;
    border-bottom: 0px solid transparent;
    border-left: 45px solid transparent;
    top: 0;
    z-index: -1;
}
.top-nav {
    display: inline-block;
    float:right;
    padding-right:20px;
}
.topbar ul.top-menu-item li {
    float: left;
    padding: 0 10px;
    border-bottom: 0;
}
.topbar ul.top-menu-item li a {
    color: #fff;
    line-height: 26px;
    font-size: 13px;
}
.top-social {
    float: right;
}
ul.top-social-item {
    list-style: none;
    display: inline-block;
    float: right;
    background: #444;
    margin: 0;
    padding: 0 8px;
}
ul.top-social-item >li {
    display: inline-block;
    margin: 5px 10px;
    line-height: 20px;
}
ul.top-social-item >li a {
    color: #fff;
}
ul.top-social-item >li:hover a {
    color: #2b3752;
}


/* ---------------------------
    3. Logo
---------------------------- */
.logo-img {
    height: 90px;
}
.header-contact-info {
    float: right;
    list-style: none;
    padding: 0;
    margin: 0 -10px;
    margin-top: 22px;
}
.header-contact-info li {
    margin: 0 10px;
    font-size: 90%;
    display: flex;
    float: left;
    margin-left: 30px;
}
.header-contact-info li a {
    color: #444;
}
.header-contact-info li i {
    margin-right: 10px;
    float: left;
    padding: 5px 10px 5px 0px;
    border-right: 1px solid #f5f5f5;
    font-size: 36px;
}
/* ---------------------------
    4. Main Menu
---------------------------- */

.navbar {
    border: 0px;
}
.main-menu nav {
    margin-bottom: 0;
}
.main-menu .nav >li >a {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000;
}
.nav>li>a:focus,
.nav>li>a:hover {
    text-decoration: none;
    background-color: transparent;
    color: #2b3752;
}
.nav > li > ul {
    background-color: #f9f9f9;
    border: none;
    border-radius: 0;
    min-width: 190px;
    margin-top: 0;
    padding: 0;
}
.nav > li > ul.dropdown-menu > li {
    position: relative;
}
.nav > li > ul.dropdown-menu > li > ul.dropdown-menu {
    background-color: #f9f9f9;
    right: 100%;
    top: 0;
    border: none;
    border-radius: 0;
    min-width: 190px;
    margin-top: 0;
    padding: 0;
}
.nav > li > ul > li > a,
.nav > li > ul > li > ul > li > a {
    display: block;
    padding: 10px;
    color: #333;
    cursor: pointer;
    padding: 12px 15px;
}
.nav > li > ul > li .caret {
    float: right;
    margin-top: 6px;
}
.navbar-offcanvas {
    background: #fff;
}
.nav > li > ul.dropdown-menu > li:hover a {
    background-color: #2b3752;
    color: #fff;
}
.nav > li > ul.dropdown-menu > li:hover > ul > li a {
    background-color: #f9f9f9;
    color: #333;
}
.nav > li > ul.dropdown-menu > li > ul > li:hover a {
    background-color: #2b3752;
    color: #fff;
}
.mega .nav,
.mega .collapse,
.mega .dropup,
.mega .dropdown {
    position: relative;
}
li.dropdown.mega-menu {
    position: static;
}
.mega .container {
    position: relative;
}
.mega .dropdown-menu {
    left: auto;
}
.mega .dropdown.mega-menu .dropdown-menu {
    margin: 0 -51px;
    left: 0;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 27px 0px;
}
.mega-menu-item li a {
    display: block;
    padding: 10px;
    color: #333;
    cursor: pointer;
    border-bottom: 1px solid #f5f5f5;
    padding: 12px 15px;
}
.nav > li.mega-menu > ul.dropdown-menu > li:hover a {
    background: #f9f9f9;
    color: #333;
}
.nav li.mega-menu ul.dropdown-menu .mega-menu-item li:hover a {
    background-color: #2b3752;
    color: #fff;
}
.mega-menu-text {
    padding: 15px 15px 15px 0;
}
.mega-menu-text img {
    margin-top: 10px;
}
.mega-menu-text p {
    margin-top: 20px;
}

/* ---------------------------
	5. Slider
---------------------------- */
.slider {
    position: relative;
}
.tp-caption.large_text,
.large_text {
    color: #fff;
    font-weight: 600;
    font-size: 46px;
    line-height: 40px;
    font-family: 'Lustria', serif;
    margin: 0px;
    white-space: nowrap;
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 0.498039) 0px 2px 5px;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-style: none
}
.tp-caption.medium_text,
.medium_text {
    color: #fff;
    font-weight: 600;
    font-size: 28px;
    line-height: 20px;
    font-family: 'Lustria', serif;
    margin: 0px;
    white-space: nowrap;
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 0.498039) 0px 2px 5px;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-style: none
}
.tp-caption.small_text,
.small_text {
    color: #ffffff;
    text-shadow: rgba(0, 0, 0, 0.498039) 0px 2px 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Lustria', serif;
    margin: 0px;
    white-space: nowrap;
    text-decoration: none;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-style: none
}
.large_text {
    color: #fff;
    font-weight: 600;
    font-size: 46px;
    line-height: 40px;
    font-family: 'Lustria', serif;
    margin: 0px;
    white-space: nowrap;
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 0.498039) 0px 2px 5px;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-style: none
}
.tp-caption.large_text {
    font-size: 70px;
}
.medium_text {
    color: #fff;
    font-weight: 600;
    font-size: 28px;
    line-height: 20px;
    font-family: 'Lustria', serif;
    margin: 0px;
    white-space: nowrap;
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 0.498039) 0px 2px 5px;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-style: none
}
.tp-caption.medium_text {
    font-size: 50px;
}
.small_text {
    color: #ffffff;
    text-shadow: rgba(0, 0, 0, 0.498039) 0px 2px 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    white-space: nowrap;
    text-decoration: none;
    background-color: transparent;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-style: none
}
.tp-caption.small_text {
    font-size: 34px;
}
.tp-caption span {
    border-bottom: 3px solid #2b3752;
}
.tp-caption .slider-btn {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    font-size: 20px;
    padding: 8px 40px;
    border-color: #2b3752;
    background: #2b3752;
    border-radius: 0 20px 0 20px;
    border-bottom: 3px solid #d6d6d6;
    text-decoration: none;
    text-shadow: none;
    -moz-transition: all linear .3s;
    -o-transition: all linear .3s;
    -webkit-transition: all linear .3s;
    transition: all linear .3s;
}
.tp-caption .slider-btn:hover {
    color: #fff;
    border-color: #444;
    background: #444;
}

/* ---------------------------
	6. Page Title
---------------------------- */
.page-title {
    position: relative;
}
.page-title h2 {
    float: left;
    margin: 0 15px 0 0;
    text-transform: uppercase;
}
.page-title ol.breadcrumb {
    display: inline-block;
    background: none;
    padding: 0;
    margin: 2px 0 0 0;
    line-height: 32px;
}
.page-title ol.breadcrumb li a {
    color: #2b3752;
}
.page-title ol.breadcrumb li.active {
    color: #2b3752;
}

/* ---------------------------
	7. Features
---------------------------- */
.feature {
    position: relative;
    z-index: 40;
}
.feature-item {
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    border-bottom: 3px solid #444444;
    color: #fff;
    padding: 40px 0 30px 0;
    margin-bottom: 50px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 70px;
}
.feature-icon span {
    display: inline-block;
    text-align: center;
    font-size: 65px;
    line-height: 36px;
}
.feature-item .feture-content {
    padding-bottom: 10px;
}
.feature-item .feture-content h3 {
    padding: 15px 30px 15px 30px;
    margin: 0;
    margin-top: 20px;
    background: #444;
    font-size: 18px;
    color: #fff;
}
.feature-item .feture-content p {
    padding: 0 30px 15px 30px;
    margin-bottom: 15px;
    background: #444;
}
.feature-item .read-more a {
    background: none;
    border: 0;
    border-bottom: 2px solid #fff;
    color: #fff;
    padding: 0;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    -moz-transition: all linear .3s;
    -o-transition: all linear .3s;
    -webkit-transition: all linear .3s;
    transition: all linear .3s;
}
.feature-item .read-more a:hover {
    padding: 0 50px !important;
}

/* ---------------------------
    8. Counting
---------------------------- */
.counting-item {
    overflow: hidden;
}
.counting-item .counting-icon {
    font-size: 68px;
    margin-right: 10px;
    color: #e7dd2d;
}
.counting-item .counting-content .counting-number {
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    letter-spacing: 3px;
}
.counting-item .counting-content .counting-title {
    margin-top: 10px;
    color: #e7dd2d;
    font-size: 18px;
    line-height: 25px;
}

/* ---------------------------
    9. Industries About
---------------------------- */
.industries-info {
    padding-bottom: 0;
}
.nav-tabs {
    border-bottom: 1px solid transparent;
}
.industries-tab .tab-pane h4 {
    margin-top: 20px;
}
.industries-tab .nav-tabs li.active a {
    position: relative;
    border: 1px solid #f5f5f5;
    border-radius: 0;
    padding: 48px 14px 15px 15px;
    color: #444;
}
.industries-tab .nav-tabs li.active a {
    background: #2b3752;
    color: #fff;
    border-color: #2b3752;
}
.industries-tab .nav-tabs li a {
    position: relative;
    border: 1px solid #f5f5f5;
    border-radius: 0;
    padding: 48px 14px 15px 15px;
    color: #444;
    text-align: center;
    background: rgba(255,255,255,0.7);
}
.industries-tab .nav-tabs li.active a i {
    color: #fff;
}
.industries-tab .nav-tabs li a i {
    position: absolute;
    top: 10px;
    font-size: 26px;
    color: #737373;
}
.industries-tab .tab-content {
    padding-top: 10px;
}
.industries-work-flow {
    padding-bottom: 50px;
    overflow: hidden;
}
.industries-work-flow .industries-work-title {
    margin-top: 45px;
    margin-bottom: 20px;
}
.industries-work-flow .col-sm-3 {
    padding-right: 0px;
    padding-left: 0px;
}
.industries-work-flow .work-flow-item {
    border: 1px solid #f5f5f5;
    margin: 1px;
    padding: 20px 0 20px 10px;
    margin-bottom: 20px;
}
.industries-work-flow .work-flow-item .work-flow-icon {
    float: left;
}
.industries-work-flow .work-flow-item .work-flow-icon span {
    display: inline-block;
    margin-right: 10px;
    width: 45px;
    height: 45px;
    background: #2b3752;
    color: #fff;
    text-align: center;
    line-height: 54px;
    border-radius: 0 20px 0 20px;
    border-bottom: 3px solid #d6d6d6;
}
.industries-work-flow .work-flow-item .work-flow-icon span i {
    font-size: 24px;
    line-height: 36px;
}
.industries-work-flow .work-flow-title {
    margin-top: 10px;
    line-height: 24px;
    font-size: 14px;
}

/* Home Style 2 */
.industries2-feature-item {
    margin-bottom: 30px;
}
.industries2-feature-item .feature2-icon {
    float: left;
}
.industries2-feature-item .feature2-icon span {
    display: inline-block;
    text-align: center;
    font-size: 46px;
    line-height: 46px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 85px;
    height: 85px;
    background: #2b3752;
    color: #fff;
    border-radius: 0 20px 0 20px;
    border-bottom: 0;
    padding-top: 18px;
}
.industries2-feature-item .feature2-text h3 {
    font-size: 18px;
    margin-top: 10px;
    line-height: 24px;
}

.feature2-text {
    padding-left: 100px;
}

/* ---------------------------
    10. Call to Action
---------------------------- */
.call-to-action h3 {
    color: #fff;
    margin: 0;
    line-height: 34px;
}
.call-to-action h3 strong {
    color: #2b3752;
    font-weight: 600;
}

/* ---------------------------
    11. Projects
---------------------------- */
.project-item {
    background: #fbfbfb;
    overflow: hidden;
    margin-bottom: 30px;
    display: table;
}
.project-item .project-item-info {
    float: none; 
    display: table-cell;
    padding: 15px;
    padding-left: 30px;
    vertical-align: middle;
}
.project-item .project-item-img {
    float: none; 
    width: 48%;
    padding: 0px;
    display: table-cell;
    vertical-align: middle;
}
.project-item .project-item-img a img {
    width: 100%;
    height: auto;
}
.project-item .project-item-date {
    border: 1px solid #f5f5f5;
    padding: 2px 20px;
    margin-right: 0;
    font-size: 12px;
    margin-top: 10px;
    background: #fff;
    position: relative;
}
.project-item .project-item-title {
    font-family: 'Lustria', serif;
    font-weight: 600;
    margin-top: 15px;
    font-size: 18px;
}
.project-item .project-item-meta {
    overflow: hidden;
    padding-bottom: 3px;
}
.project-item p {
    color: #505050;
}
.project-item .project-item-meta li {
    float: left;
    color: #929292;
    padding-right: 10px;
}
.project-item .project-item-meta li a {
    color: #929292;
    padding-left: 5px;
    font-style: italic;
}
.projects-article {
    width: 100%;
    float: left;
    border: 1px solid #f5f5f5;
    margin-bottom: 40px;
}
.projects-article .img-thumb img {
    width: 100%;
}
.projects-article .date,
.news-details-date .date {
    margin-top: 30px;
    padding: 3px 15px;
    border: 1px solid #f5f5f5;
    background: #fff;
    color: #444 !important;
    position: relative;
    font-size: 13px !important;
}
.projects-article .date:after,
.news-details-date .date:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 1px;
    background: #f5f5f5;
    top: 12px;
    right: -40px;
}
.projects-article .project-content h3 {
    padding: 15px 0 5px 0;
    margin: 0;
}
.projects-article .project-content h3 a {
    color: #444;
    text-transform: uppercase;
    font-size: 18px;
}
.projects-article .project-content h3 a:hover {
    color: #2b3752;
}
.projects-article .project-content .meta-data {
    margin-bottom: 10px;
}
.projects-article .project-content li {
    display: inline;
    position: relative;
    margin-right: 20px;
    color: #929292;
    font-style: italic;
}
.projects-article .project-content li a {
    color: #929292;
}
.projects-article .project-content li a:hover {
    color: #2b3752;
}
.projects-article .project-content li:before {
    color: #333;
    margin-right: 5px;
    font-family: 'FontAwesome';
    font-style: normal;
}
.projects-article .project-content li.user:before {
    content: "\f007";
}
.projects-article .project-content li.category:before {
    content: "\f07c";
}
.projects-article .project-content li.comment:before {
    content: "\f27a";
}
.projects-article .project-content p {
    margin-bottom: 15px;
}
.projects-article .project-content a {
    margin-bottom: 30px;
}
.project-navigation {
    padding: 0;
    margin: 0;
    text-align: left;
    color: #999;
    border-bottom: 1px solid #eee;
    margin-bottom: 50px;
}
.project-navigation ul {
    margin-bottom: -23px;
}

/* ---------------------------
    12. Circle Percentage
---------------------------- */
.chart-progress {
    padding: 15px;
    padding-top: 0;
    position: relative;
}
.chart-progress:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 65%;
    left: 0;
    bottom: 0;
    border: 1px solid #fff;
    border-radius: 0 40px 0 40px;
    z-index: 1;
}
.circle-item {
    text-align: center;
}
.circle-item h3 {
    color: #fff;
    margin: 0;
    font-size: 18px;
    line-height: 34px;
}
.circle-item canvas {
    background: #2e2f31;
    border-radius: 50%;
    z-index: 2;
    position: relative;
}
.chart-percent {
    font-size: 32px;
    font-weight: 400;
    position: absolute;
    left: 0;
    top: 40px;
    right: 0;
    z-index: 3;
}

/* ---------------------------
    13. Services
---------------------------- */
.service-horizontal-tabs .nav {
    border-bottom: 0;
    border-right: 1px solid #f5f5f5;
}
.service-horizontal-tabs .nav li {
    width: 100%;
    float: left;
    border: 0;
    position: relative;
}
.service-horizontal-tabs .nav li.active a {
    background: #2b3752;
    color: #fff;
    border-radius: 0;
    border-color: transparent;
}
.service-horizontal-tabs .nav > li > a {
    margin-right: 30px;
    color: #444;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}
.service-horizontal-tabs .nav li a:hover {
    border-color: transparent;
    border-radius: 0;
}
.service-horizontal-tabs .nav li.active:after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-top: 12px solid transparent;
    border-right: 12px solid #f5f5f5;
    border-bottom: 12px solid transparent;
    border-left: 12px solid transparent;
    top: 10px;
    right: 0;
}
.service-horizontal-tabs .tab-content .tab-pane {
    padding: 0;
}
.service-horizontal-tabs .tab-content .tab-pane img {
    width: 100%;
}
.service-info a.service-btn {
    background: #2b3752;
    color: #fff;
    border-color: #2b3752;
    border-radius: 0;
    padding: 14px 5px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    display: inline-block;
    text-align: center;
}
.service-message {
    border: 1px solid #f5f5f5;
    padding: 25px 20px 15px 20px;
}
.service-message h3 {
    margin-top: 0;
    color: #2b3752;
    font-size: 18px;
}
.service-message img {
    width: 110px;
    margin-top: 7px;
    display: table;
    margin-left: auto;
    margin-right: auto;
}
.service-read {
    text-align: center;
}
.service-read .read-more {
    margin: 0 !important;
    border-radius: 7px;
    border: 0;
    background: #2b3752;
    color: #fff;
    padding: 13px 20px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    display: inline-block;
}
.service-read .discount {
    margin: 0 !important;
    border-radius: 0;
    border: 0;
    background: #444;
    color: #fff;
    padding: 13px 20px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    display: inline-block;
}

/* ---------------------------
    14. Call To Action Contact
---------------------------- */
.cta-contact h3 {
    color: #fff;
    margin: 0;
    line-height: 34px;
}
.cta-contact h3 strong {
    color: #2b3752;
    font-weight: 600;
}
.cta-contact p {
    padding-left: 10px;
    margin-top: 10px;
    border-left: 3px solid #2b3752;
}
.cta-button .cta-btn {
    margin-top: 30px;
}

/* ---------------------------
    15. Team
---------------------------- */
.team-item {
    background: #fbfbfb;
    overflow: hidden;
    margin-bottom: 30px;
}
.team-item .team-img {
    float: none;
    width: 48%;
    padding: 0;
    /*display: table-cell;*/
    vertical-align: middle;
}
.team-item .team-info {
    float: none;
    width: auto; 
    padding: 15px;
    padding-left: 30px;
    /*display: table-cell;*/
    vertical-align: middle;
}
.team-item .team-img a img {
    width: 100%;

    height: auto;
}
.team-item .team-item-date {
    border: 1px solid #f5f5f5;
    padding: 2px 20px;
    margin-right: 0;
    font-size: 12px;
    margin-top: 10px;
    background: #fff;
    position: relative;
}
.team-item .team-name {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-top: 0;
}
.team-item .team-designation {
    font-size: 12px;
    margin-bottom: 5px;
    color: #888;
    font-style: italic;
    display: block;
}
.team-item .team-social {
    list-style: none;
    display: block;
    padding: 0;
    margin: 10px -8px;
}
.team-item .team-social li {
    display: inline-block;
    margin: 0 8px;
    width: 32px;
    height: 32px;
    border: 1px solid #f5f5f5;
    background: #fff;
    padding: 8px;
    text-align: center;
    border-radius: 0 15px 0 15px;
    margin-right: 0px;
    -moz-transition: all linear .3s;
    -o-transition: all linear .3s;
    -webkit-transition: all linear .3s;
    transition: all linear .3s;
}
.team-item .team-social li:hover {
    background: #2b3752;
}
.team-item .team-social > li > a {
    display: block;
    font-size: 16px;
    line-height: 16px;
    color: #999;
}
.team-item .team-social li:hover i {
    color: #fff;
}
.team-item .team-phone span,
.team-item .team-email span {
    font-weight: 600;
}

/* ---------------------------
	16. Price
---------------------------- */
.price {
    margin: 0px 0px;
    padding: 70px 0 60px 0;
    background-color: #fbfbfb;
}
.price-item {
    position: relative;
    text-align: center;
}
.price-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 80%;
    left: 0;
    top: 10%;
    background: #7e7e7e;
    border-radius: 0 100px 0 100px;
    z-index: 1;
}
.price-item .price-box {
    background-color: #ffffff;
    padding: 50px 20px;
    margin: 0 30px;
    border: 1px solid #e5e5e5;
    position: relative;
    z-index: 2;
}
.price-box .pricing-header .pricing-title {
    color: #2b3752;
    margin-top: 0;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.price-box .pricing-header .pricing-price {
    font-size: 24px;
    line-height: 24px;
}
.price-box .pricing-header .pricing-duration {
    font-size: 16px;
    line-height: 16px;
}
.price-box .pricing-features {
    padding: 20px 0;
}
.price-box .pricing-features ul li {
    display: block;
    padding: 5px 0;
}
.pricing-footer .btn {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    color: #fff;
    border-color: #444;
    background: #444;
    padding: 5px 15px;
    border-radius: 0 20px 0 20px;
    border-bottom: 3px solid #d6d6d6;
}
.pricing-footer .btn:hover {
    color: #fff;
    border-color: #2b3752;
    background: #2b3752;
}
.pricing2-item {
    background: url(../images/bg-pricing.jpg) no-repeat scroll 100% 100% rgba(0, 0, 0, 0);
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 20px;
    margin-top: 85px;
    text-align: center;
}
.pricing2-box {
    background: none repeat scroll 0 0 rgba(245, 245, 245, 0.91);
    border-radius: 10px;
    list-style: outside none none;
    margin: 0;
    padding: 95px 0 30px 0;
    position: relative;
    width: 100%;
    z-index: 99;
    border: 0;
}
.pricing2-title {
    margin-top: 0;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.pricing2-box span.pricing2-price {
    font-size: 24px;
    line-height: 24px;
    width: 110px;
    height: 110px;
    display: block;
    background: none repeat scroll 0 0 #7e7e7e;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    color: #ffffff;
    font-weight: bold;
    position: absolute;
    text-align: center;
    top: -62px;
    left: 50%;
    margin-left: -55px;
    z-index: 0;
    padding-top: 30px;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.pricing2-item:hover .pricing2-price {
    background: #2b3752;
}
.pricing2-box span.pricing2-price:before {
    background: none repeat scroll 0 0 #7e7e7e;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.26;
    position: absolute;
    top: 0;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    width: 100%;
    z-index: -1;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.pricing2-box span.pricing2-price:after {
    background: none repeat scroll 0 0 #7e7e7e;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.10;
    position: absolute;
    top: 0;
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
    width: 100%;
    z-index: -1;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.pricing2-item:hover .pricing2-price:before {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.4s ease 0.2s;
    -moz-transition: all 0.4s ease 0.2s;
    -ms-transition: all 0.4s ease 0.2s;
    -o-transition: all 0.4s ease 0.2s;
    transition: all 0.4s ease 0.2s;
}
.pricing2-item:hover .pricing2-price:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.4s ease 0.1s;
    -moz-transition: all 0.4s ease 0.1s;
    -ms-transition: all 0.4s ease 0.1s;
    -o-transition: all 0.4s ease 0.1s;
    transition: all 0.4s ease 0.1s;
}
.pricing2-duration {
    position: absolute;
    color: #fff;
    top: 0;
    left: 42%;
    font-size: 16px;
    line-height: 16px;
}
.pricing2-features {
    padding: 20px 0;
}
.pricing2-features ul {
    border-top: 1px solid #ffffff;
    list-style: none;
    padding: 0;
    margin: 0;
}
.pricing2-features ul li {
    border-bottom: 1px solid #ffffff;
    color: #666666;
    float: left;
    font-size: 14px;
    letter-spacing: 0.3px;
    padding: 13px 0;
    text-align: center;
    width: 100%;
}
.pricing2-footer a {
    margin-top: 30px;
}

/* ---------------------------
    17. Clients
---------------------------- */
.clients {
    overflow: hidden;
}
.clients-img {
    margin-bottom: -400px;
}
.clients-logo {
    position: relative;
}
.clients-logo a img {
    display: table;
    margin: 0 auto;
}
.clients-logo .col-md-4:nth-child(3),
.clients-logo .col-md-4:nth-child(6) {
    border-right: 0;
}

/* ---------------------------
    18. Testimonial
---------------------------- */
.testimonial-item {
    border: 1px solid #f5f5f5;
    padding: 15px;
    padding-bottom: 0px;
    margin-bottom: 15px;
}
.testimonial-item a {
    margin-right: 15px;
}
.testimonial-item img {
    border-radius: 0 20px 0 20px;
    border: 0;
    width: 64px;
}
.testimonial-item blockquote {
    font-size: 14px;
    border-left: 1px solid #f5f5f5;
}
.testimonial-body {
    padding-left: 80px;
}
.testimonial-body blockquote {
    font-size: 14px;
    border-left: 1px solid #f5f5f5;
    margin-left: 80px;
    margin: 0 0 7px;
    padding: 0 15px 20px;
}
.testimonial-body footer {
    margin-top: 20px;
    font-size: 14px;
    color: #444;
}
.testimonial2-carousel .testimonial-message {
    position: relative;
    font-style: italic;
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 26px;
}
.testimonial2-carousel .testimonial-message:before {
    content: "\f0a4";
    font-family: FontAwesome;
    position: absolute;
    color: #2b3752;
    font-size: 20px;
    left: 0;
    top: -5px;
    padding-top: 3px;
}
.testimonial2-carousel .testimonial-message::first-letter {
    padding-left: 30px;
}
.testimonial2-carousel .item img {
    float: left;
    margin-right: 30px;
    margin-bottom: 20px;
    border-radius: 0 70px 0 70px;
    border-bottom: 3px solid #f5f5f5;
}
.testimonial2-carousel .pro-client-name {
    font-size: 18px;
    font-weight: 600;
    margin-top: 50px;
}
.testimonial2-carousel .testimonial-designation {
    font-style: italic;
}
.carousel2-pagination {
    display: inline-block;
    position: absolute;
    margin-left: 230px;
    bottom: 50px;
}
.carousel2-pagination a {
    border-radius: 0 15px 0 15px;
    background: #fff;
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    width: 25px;
    height: 25px;
    text-align: center;
    border: 2px solid #2b3752;
    color: #888;
    margin: 5px 2px 0;
    -webkit-transition: 400ms;
    -o-transition: 400ms;
    transition: 400ms;
    -webkit-box-sizing: initial;
    -moz-box-sizing: initial;
    box-sizing: initial;
}
.carousel2-pagination a:hover {
    background: #333;
    color: #fff;
}
.carousel2-pagination a i {
    font-size: 24px;
}

/* ---------------------------
    19. Bottom CTA
---------------------------- */
.bottom-cta {
    padding: 35px 0 27px 0;
    background: #5a73ab;
}
.bottom-cta h3 {
    margin: 0;
    line-height: 30px;
}
.bottom-cta h3 strong {
    color: #fff;
}

/* ---------------------------
	20. FAQS
---------------------------- */
.fags {
    margin: 0px 0px;
    padding: 70px 0 60px 0;
    background-color: #fbfbfb;
}
.faq1-item {
    background: #fff;
    padding: 15px 15px 8px 18px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}
.faq1-item h4 {
    line-height: 30px;
}
.faq1-item h4 strong {
    background: #444;
    color: #fff;
    padding: 3px 10px;
}
.faq1-item p strong {
    background: #2b3752;
    color: #fff;
    padding: 3px 10px;
}
.panel-group .panel {
    margin-bottom: 0;
    border-radius: 0px;
    border: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.faq2 .panel-heading {
    position: relative;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
    background: none;
    font-size: 16px;
    font-weight: 400;
    padding-left: 25px;
    padding: 0;
}
.faq2 .panel-heading:before {
    content: "\f059";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    top: 20%;
    font-size: 20px;
    color: #33383d;
}
.panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body {
    border-top: 0;
    border-bottom: 1px solid #e5e5e5;
}
.faq2 .panel-heading .panel-title {
    font-weight: 400;
}
.faq2 .panel-heading a {
    position: relative;
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 10px 10px 30px;
}
.faq2 .panel-heading a:focus,
.faq2 .panel-heading a:active {
    text-decoration: none
}
.faq2 .panel-title > a.accordion-toggle::after,
.faq2 a[data-toggle="collapse"]::after {
    content: "\f077";
    font-family: 'FontAwesome';
    float: right;
    color: gray;
    font-weight: normal;
}
.faq2 .panel-title > a.accordion-toggle.collapsed::after,
.faq2 a.collapsed[data-toggle="collapse"]::after {
    content: "\f078";
}

/* ---------------------------
	21. Industry Page
---------------------------- */
.industry-item {
    border: 1px solid #f5f5f5;
    margin-bottom: 30px;
}
.industry-item .industry-img img {
    width: 100%;
    height: 100%;
}
.industry-item .industry-info h3 {
    margin-top: 0;
    padding-top: 30px;
}
.industry-item .industry-info a {
    margin: 20px 0 30px 0;
}

/* ---------------------------
	22. Coming Soon
---------------------------- */
.coming-soon {
    background-color: #2b3752;
    text-align: center;
    color: #fff;
    padding-top: 10%;
    padding-bottom: 10%;
    width: 100%;
    min-height: 100vmin;
}
.coming-soon-content h1 {
    margin-top: 50px;
    font-size: 42px;
    margin-bottom: 10px;
    font-weight: 700;
}
.coming-soon-content h3 {
    font-size: 28px;
    font-weight: 300;
}
.coming-soon-social {
    margin-bottom: 50px;
}
.coming-soon-social .social-icons li {
    display: inline-block;
    margin: 0 15px;
}
.coming-soon-social .social-icons li a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 24px;
    -webkit-transition: color 400ms;
    transition: color 400ms;
}
.coming-soon-social .social-icons li a:hover {
    color: #fff;
}
#comingsoon-countdown {
	margin: 60px 0;
}
.coming-soon .days,
.coming-soon .hours,
.coming-soon .minutes,
.coming-soon .seconds {
    display: inline-block;
    margin: 20px 15px;
}
.coming-soon .days .number,
.coming-soon .hours .number,
.coming-soon .seconds .number,
.coming-soon .minutes .number {
    width: 115px;
    height: 120px;
    line-height: 120px;
    border: 1px solid #fff;
    border-radius: 4px;
    display: inline-block;
    font-size: 48px;
    font-weight: bold;
}
.coming-soon .days .string,
.coming-soon .minutes .string,
.coming-soon .seconds .string,
.coming-soon .hours .string {
    display: block;
    font-size: 18px;
    margin-top: 10px;
}

/* ---------------------------
	23. 404 Page
---------------------------- */
.error-heading {
    width: 100%;
    height: 300px;
    background: #f5f5f5 url(../images/error.png) no-repeat center;
    background-size: 200px;
}
.error-page-inner {
    width: 100%;
    display: table;
    text-align: center;
}
.error-message {
    font-size: 32px;
    line-height: 36px;
    margin-top: 30px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 400;
}
.error-message span {
    font-weight: 700;
}
.error-code {
    font-weight: bold;
    font-size: 96px;
    line-height: 1;
    margin: 0 0 20px 0;
    padding: 0;
}
.error-page-inner .btn {
    background: #2b3752;
    border-color: #2b3752;
    border-radius: 0 20px 0 20px;
    padding: 4px 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 18px;
}
.error-page-inner .btn:hover {
    background: #444;
    border-color: #444;
    color: #fff;
}

/* ---------------------------
	24. Portfolio
---------------------------- */
.portfolio-filter {
    margin-bottom: 30px;
}
.portfolio-filter .btn {
    text-transform: capitalize;
    margin-right: 8px;
    padding: 7px 25px;
    background: #2D2D2D;
    margin-bottom: 10px;
}
.portfolio-filter .btn:hover {
    background: #2b3752;
}
.portfolio-filter .current {
    background: #2b3752;
    border-color: #2b3752;
    color: #fff;
}
.portfolio-container .thumb-img {
    position: relative;
    overflow: hidden;
}
.portfolio-container .thumb-img img {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: all 400ms;
    transition: all 400ms;
}
.portfolio-container .thumb-img .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 400ms;
    -webkit-transition: opacity 400ms;
}
.portfolio-container .thumb-img .button {
    height: 100%;
    width: 100%;
    display: table;
}
.portfolio-container .btn-center {
    display: table-cell;
    vertical-align: middle;
}
.portfolio-container .btn-center a {
    border-radius: 0 20px 0 20px;
    border-color: #2b3752;
    border-bottom: 3px solid #949292;
    border-left: 3px solid #949292;
    background: #2b3752;
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
    -webkit-transition: all 400ms;
    transition: all 400ms;
}
.portfolio-container .btn-center a:hover {
    background: #444;
    border-color: #444;
    border-bottom: 3px solid #949292;
    border-left: 3px solid #949292;
}
.portfolio-container .btn-center .zoom {
    margin-right: 10px;
}
.portfolio-container .portfolio-info {
    background: #f5f5f5;
    padding: 20px;
    font-size: 13px;
    line-height: 13px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}
.portfolio-container .portfolio-info:hover {
    background: #333;
    color: #fff;
}
.portfolio-container .portfolio-info h3 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 16px;
    line-height: 16px;
}
.portfolio-container .portfolio-info h3 > a {
    text-decoration: none;
    color: #000;
    -webkit-transition: color 400ms;
    transition: color 400ms;
}
.portfolio-container .portfolio-info:hover h3 > a {
    color: #fff;
}
.portfolio-item {
    transition: transform 250ms ease-out, opacity 250ms ease-out;
}
.portfolio-item:hover .thumb-img .overlay {
    opacity: 1;
}
.portfolio-item:hover .thumb-img img {
    -webkit-transform: scale3d(1.15, 1.15, 1);
    transform: scale3d(1.15, 1.15, 1);
}
.portfolio-item:hover .btn-center a {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
.portfolio-container .grid-item {
    margin-bottom: 30px;
}

/* ---------------------------
	25. News
---------------------------- */
.news1-item {
    padding: 5px;
    border: 1px solid #f5f5f5;
    margin-bottom: 30px;
}
.news1-item .news1-thumb a img {
    width: 100%;
}
.news1-info {
    padding: 15px;
}
.news1-info .date span {
    margin-top: -32px;
    position: absolute;
    padding: 3px 15px;
    border: 1px solid #f5f5f5;
    background: #fff;
    color: #444;
    font-size: 13px;
}
.news1-info h3 {
    padding: 15px 0 15px 0;
    margin: 0;
    font-weight: 400;
}
.news1-info h3 a {
    color: #444;
    text-transform: uppercase;
    font-size: 18px;
}
.news1-info .btn {
    margin: 10px 0 10px 0;
}
.news-details-date {
    margin-bottom: 15px;
}

/* ---------------------------
	26. Blog
---------------------------- */
article.article {
    margin-bottom: 50px;
}
.blog-img {
    margin-bottom: 30px;
}
.carousel-left,
.carousel-right {
    position: absolute;
    top: 50%;
    font-size: 24px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin-top: -18px;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    -webkit-transition: 400ms;
    transition: 400ms;
}
.carousel-left:hover,
.carousel-right:hover {
    background: #2b3752;
}
.carousel-right {
    right: 10px;
    left: auto;
}
.carousel-left {
    left: 10px;
}
.post-format {
    display: block;
    width: 48px;
    height: 48px;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    color: #fff;
    background: #2b3752;
    border-radius: 3px;
    float: left;
}
.blog-header {
    margin-bottom: 20px;
}
.blog-header ul {
    margin-left: 65px;
    overflow: hidden;
}
.blog-header ul li {
    position: relative;
    float: left;
    margin-right: 20px;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 2;
}
.blog-header ul li a {
    color: #2b3752;
}
.blog-header ul li:before {
    color: #666;
    margin-right: 5px;
    font-family: FontAwesome;
}
.blog-header ul .user:before {
    content: "\f007";
}
.blog-header ul .category:before {
    content: "\f115";
}
.blog-header ul .date:before {
    content: "\f133";
}
.blog-header ul .view:before {
    content: "\f06e";
}
.blog-header h2 {
    margin: 8px 0 0;
    font-size: 24px;
    line-height: 1;
    margin-left: 65px;
    font-weight: 600;
}
.blog-header h2 a {
    color: #000;
}
article .post-quote {
    background: #2b3752;
    position: relative;
    color: rgba(255, 255, 255, 0.9);
    overflow: hidden;
}
article .post-quote:before {
    position: absolute;
    left: -40px;
    font-size: 300px;
    content: "\f10e";
    font-family: FontAwesome;
    transform: rotate(15deg);
    top: -75px;
    color: rgba(255, 255, 255, 0.15);
}
article iframe {
    width: 100%;
    border: none;
}
article .post-quote p {
    font-size: 16px;
}
article .post-quote blockquote small {
    color: #FFF;
    font-weight: 600;
    font-size: 20px;
}
article .post-quote blockquote {
    border: none;
}
.blog-content {
    overflow: hidden;
}
.blog-content p {
    margin-bottom: 25px;
}
.blog-content blockquote {
    margin-left: 40px;
    margin-right: 40px;
}
.blog-content blockquote p {
    font-style: italic;
}
.blog-content img {
    margin-bottom: 30px;
}
.alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
}
.alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
}
.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.blog-content .btn-default {
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 0 20px 0 20px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    display: inline-block;
}
.blog-content .btn-default:hover {
    color: #fff;
    border-color: #2b3752;
    background: #2b3752;
}
.pagination>li>a,
.pagination>li>span {
    color: #444444;
}
.pagination>.active>a,
.pagination>.active>span {
    border-color: #2b3752;
    background-color: #2b3752;
}
.pagination>li>a:hover,
.pagination>li>a:focus,
.pagination>li>span:hover,
.pagination>li>span:focus {
    color: #444444;
}
.pagination>.active>a:hover {
    background-color: #2b3752;
    border-color: #2b3752;
}
.single-article {
    border-bottom: 1px solid #eee;
}
.post-tag {
    margin-bottom: 45px;
}
.post-tag span {
    padding: 0 10px 0 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}
.post-tag .tags {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline;
}
.post-tag .tags li {
    display: inline-block;
    list-style: none;
    padding: 8px 15px;
    margin: 0 5px 5px 0;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 20px;
}
.post-tag .tags li a {
    color: #333;
}
.author {
    border: 1px solid #eee;
    margin: 0 0 50px 0;
    padding: 10px;
    overflow: hidden;
}
.author img {
    float: left;
    display: block;
    margin: 0 10px 0 0;
}
.author .author-info h3 {
    margin-top: 3px;
    text-transform: uppercase;
    font-size: 16px;
}
.author .author-info h3 a {
    color: #444;
}
.comment-list {
    margin-bottom: 50px;
}
.comment-list .author-response {
    padding: 30px 0 25px 0;
    margin: 0;
    border-bottom: 1px solid #eee;
}
.comment-list .author-response .comment-link {
    float: right;
    margin-left: 8px;
}
.comment-list .author-response .comment-link a {
    font-size: 11px;
    color: #999;
    text-decoration: underline;
}
.comment-list .author-response img {
    float: left;
    margin-right: 15px;
    width: 85px;
    height: 85px;
    border-radius: 110px;
    border: 1px solid #eee;
}
.comment-list .author-response .commneter {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
}
.comment-list .author-response .commneter a {
    color: #333;
    display: block;
}
.comment-list .author-response .comment-date {
    padding: 0 4px 0 0;
    margin: 0 8px 0 0;
    font-size: 14px;
    color: #444;
    font-weight: 300;
}
.comment-list .author-response p {
    padding: 4px 0;
}
.single-comment h3 {
    margin: 0;
    padding: 0 0 4px 0;
    font-size: 18px;
    margin-bottom: 20px;
}
.single-comment input.text,
.single-comment textarea {
    display: block;
    width: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 20px;
    padding: 6px 12px;
}
.single-comment input:focus,
.single-comment textarea:focus {
    outline: none;
}
.single-comment input.text {
    height: 50px;
}
.single-comment textarea {
    min-height: 185px;
}
.single-comment .btn {
    margin: 30px 0;
    padding: 8px 15px;
}

/* ---------------------------
	27. Sidebar
---------------------------- */
.widget {
    margin-bottom: 50px;
}
.widget .widget-title {
    margin: 0 0 20px;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 1;
    position: relative;
}
.widget .widget-title h3 {
    margin-top: 0;
}
.widget .widget-title:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 3px;
    background: #2b3752;
    top: 30px;
    left: 2px;
}
.widget-content .search-form input {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    display: block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
}
.widget-content .search-form input:focus {
    outline: none;
}
.latest-news > .news-item {
    position: relative;
    padding: 10px 0 10px 25px;
    background: none;
    border-bottom: 1px solid #f1f1f1;
}
.latest-news > .news-item:before,
.latest-news > .news-item:before {
    content: "\f0f6";
    font-family: FontAwesome;
    font-size: 18px;
    position: absolute;
    left: 0;
}
.latest-news a {
    color: #444444;
    display: block;
}
.latest-news a:hover {
    color: #2b3752;
}
.latest-news small {
    color: #989898;
}
.important-link li {
    display: block;
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: 300ms;
    transition: 300ms;
}
.important-link li a {
    color: #444444;
    display: block;
    padding: 5px 0;
    line-height: 36px;
    padding: 2px 0;
    -webkit-transition: 300ms;
    transition: 300ms;
}
.important-link li a:hover {
    color: #2b3752;
}
.important-link li a:before {
    font-family: FontAwesome;
    content: "\f105";
    margin-right: 8px;
}
.widget-content .project-list {
    border-left: 1px solid #eee;
    padding: 0 4px;
}
.widget-content .project-list li {
    display: block;
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: 300ms;
    transition: 300ms;
}
.widget-content .project-list li:last-child {
    border-bottom: none;
}
.widget-content .project-list li a {
    padding-left: 10px;
    line-height: 50px;
    color: #444444;
}
.widget-content .project-list li a:hover {
    color: #2b3752;
}
.widget-content .ln-img {
    float: left;
}
.widget-content .ln-meta {
    margin-left: 50px;
}
.widget-content .ln-img img {
    width: 40px;
}
.widget-content .ln-post {
    margin-bottom: 10px;
}
.widget-content .ln-post .ln-meta .date {
    border: 1px solid #f5f5f5;
}
.widget-content .ln-post .ln-meta .date:after {
    background: #f5f5f5;
}
.widget-content .ln-post .ln-meta a {
    color: #444;
}
.widget-tags a {
    font-size: 13px;
    letter-spacing: 1px;
    color: #fff;
    border-color: #444;
    background: #444;
    padding: 5px 10px;
    border-radius: 0 20px 0 20px;
    border-bottom: 3px solid #d6d6d6;
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
}
.widget-tags a:hover {
    color: #fff;
    border-color: #2b3752;
    background: #2b3752;
}

/* ---------------------------
	28. Contact Us
---------------------------- */
.contact-us {
    text-align: center;
}
.contact-item .icon span {
    display: inline-block;
    text-align: center;
    font-size: 48px;
    line-height: 48px;
}
.gmap-canvas {
    height: 300px;
}
.contact-form {
    margin-top: 50px;
}
.contact-form .text,
.contact-form .textarea {
    width: 100%;
    margin-bottom: 30px;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    padding-left: 0;
    border-bottom: 1px solid #2b3752;
}
.contact-form .text:focus,
.contact-form .text:active,
.contact-form .textarea:focus,
.contact-form .textarea:active {
    outline: none;
}
.contact-form .btn {
    padding: 10px 25px;
    border-color: #2b3752;
    background: #2b3752;
    border-radius: 0 20px 0 20px;
    border-bottom: 3px solid #d6d6d6;
}
.contact-form .btn:hover {
    border-color: #444;
    background: #444;
}

/* ---------------------------
    29. Footer
---------------------------- */
.footer {
    background: #2d3538 url(../images/section-bg.jpg) repeat;
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;
    color: #fff;
    border-top: 5px solid #2b3752;
}
.section-bg-footer {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 80px 0 30px 0px;
}
.footer-item {
    margin-bottom: 50px;
}
.footer-item .footer-col-1 img {
    margin-bottom: 20px;
}
.footer-item li p {
    margin-right: 10px;
}
.footer-item a {
    color: #fff;
}
.footer-col-title h3 {
    text-transform: none;
    font-weight: 400;
    font-size: 24px;
    margin: 0 0 20px;
    position: relative;
}
.footer-col-title h3:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 3px;
    background: #6d81ae;
    top: 30px;
    left: 2px;
}
.footer-col-content ul li a {
    line-height: 34px;
}
.footer-col-content a {
    padding: 2px 0;
}
.footer-col-content ul >li >a:before {
    font-family: FontAwesome;
    content: "\f105";
    margin-right: 8px;
    color: #6d81ae;
}

.footer-col-content ul >li >a.noarrow:before {
    content: '';
    margin-right: 0;
}

.footer-col-content ul >li.level2{
    margin-left:16px;
}
.footer-col-content ul >li.level2 >a:before {
    font-family: FontAwesome;
    content: "\f105";
    margin-right: 8px;
    color: #6d81ae;
}

.footer-col-content .ln-img {
    float: left;
}
.footer-col-content .ln-meta {
    margin-left: 50px;
}
.footer-col-content .ln-img img {
    width: 40px;
}
.footer-col-content .ln-post {
    margin-bottom: 10px;
}
.ln-post .ln-meta .date {
    border: 1px solid #969696;
    padding: 2px 5px;
    margin-right: 0;
    font-size: 10px;
    margin-top: 0px;
    background: none;
    position: relative;
    display: inline;
}
.ln-post .ln-meta .date:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 1px;
    background: #969696;
    top: 8px;
    right: -40px;
}
.ln-post .ln-meta h4 {
    margin: 2px 0 0 0;
}
.ln-post .ln-meta h4 a {
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
    font-weight: 400;
    line-height: 18px;
}
.pagewrap {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 999;
}
.back-top {
    display: none;
    position: fixed;
    margin: 0;
    bottom: 2px;  
    right: 10px;
    text-align: center;
}
.back-top span {
    width: 50px;
    height: 50px;
    display: block;
    background: #444;
    color: #6d81ae;
    font-size: 26px;
    padding-top:10px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
}
.back-top a:hover span {
    background: #2b3752;
    color: #fff;
    -webkit-box-shadow: -3px 3px 0px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -3px 3px 0px 0px rgba(0, 0, 0, 0.2);
    box-shadow: -3px 3px 0px 0px rgba(0, 0, 0, 0.2);
}

/* ---------------------------
    30. Copyright
---------------------------- */
.copyright {
    background: #080808;
    color: #fff;
    text-align: left;
    padding: 10px 0;
}
.footer-col-content .input-group {
    margin-top: 20px;
}
.footer-col-content .form-control,
.footer-col-content button {
    border-radius: 0px;
    border: 1px solid transparent;
    height: 40px;
}
.footer-col-content button {
    background-color: #e7dd2d;
    color: #fff;
}
.footer-col-content button:hover {
    background-color: #b2a925;
    color: #fff;
}
.footer-col-content .form-control,
.footer-col-content .form-control:focus,
.footer-col-content .form-control:active,
.footer-col-content button:focus,
.footer-col-content button:active,
.footer-col-content button:hover {
    outline: none;
    box-shadow: none;
    border: 1px solid transparent;
}
.footer-col-content button:active,
.footer-col-content button:focus {
    background-color: #887f1c;
    color: #fff;
}

/* ---------------------------
    Loader
---------------------------- */
.implode {
    display: table;
    width: 100%;
    height: 100%;
    position: relative;
    table-layout: fixed;
}
.implode-in {
    display: table-cell;
    vertical-align: middle;
}
.loader {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20000;
    text-align: center;    
}
.acting {
    position: relative;
    width: 50px;
    height: 30px;
    margin: 0 auto;
}
.acting span {
    background-color: #355eb2;
    display: block;
    width: 1px;
    height: 30px;
    position: absolute;
    bottom: 0px;
    left: 0;
    -webkit-animation: preloader 1.5s infinite ease-in-out;
    -moz-animation:    preloader 1.5s infinite ease-in-out;
    -o-animation:      preloader 1.5s infinite ease-in-out;
    animation:         preloader 1.5s infinite ease-in-out;
    -webkit-transform-origin: center center;
    -moz-transform-origin:    center center;    
    -o-transform-origin:      center center;
    -ms-transform-origin:     center center;
    transform-origin:         center center;
}
.acting span:nth-child(2) {
    left: 11px;
    -webkit-animation-delay: 0.2s;
    -o-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
.acting span:nth-child(3) {
    left: 22px;
    -webkit-animation-delay: 0.4s;
    -o-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.acting span:nth-child(4) {
    left: 33px;
    -webkit-animation-delay: 0.6s;
    -o-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
.acting span:nth-child(5) {
    left: 44px;
    -webkit-animation-delay: 0.8s;
    -o-animation-delay: 0.8s;
    animation-delay: 0.8s;
}
.acting span:nth-child(6) {
    left: 55px;
    -webkit-animation-delay: 0.9s;
    -o-animation-delay: 0.9s;
    animation-delay: 0.9s;
}
.acting span:nth-child(7) {
    left: 66px;
    -webkit-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}
@-webkit-keyframes preloader {
    0% {
        background-color: #fff;
        height: 30px;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
    25% {
        background-color: #4186e8;
        height: 60px;
        -webkit-transform: translateY(15px);
        -moz-transform: translateY(15px);
        -ms-transform: translateY(15px);
        -o-transform: translateY(15px);
        transform: translateY(15px);
    }
    50% {
        background-color: #fff;
        height: 30px;

        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        background-color: #fff;
        height: 30px;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes preloader {
    0% {
        background-color: #fff;
        height: 30px;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    
    }
    25% {
        background-color: #4186e8;
        height: 60px;
        -webkit-transform: translateY(15px);
        -moz-transform: translateY(15px);
        -ms-transform: translateY(15px);
        -o-transform: translateY(15px);
        transform: translateY(15px);
    }
    50% {
        background-color: #fff;
        height: 30px;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        background-color: #fff;
        height: 30px;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}
