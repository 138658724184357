@media (min-width: 0px) {
    .h3, h3 {
        font-size: 18px;
    }
    .section-title {
        margin-bottom: 20px;
    }
    .section-padding {
        margin: 0px 0px;
        padding: 40px 0 40px 0px;
    }
    .back-top span {
        width: 35px;
        height: 35px;
        padding-top: 5px;
    }
    .topbar {
        display: none;
    }
    .logo-img {
        padding-top: 26px;
    }
    .navbar-offcanvas ul li {
        border-bottom: 1px dotted #e8e8e8;
    }
    .nav .dropdown .dropdown-menu li > a {
        border-bottom: 0;
        padding-left: 15px;
    }
    .main-menu {
        position: absolute;
        width: auto;
        float: left;
        border: 0;
        background: none;
        padding-right: 0;
        padding-left: 0;
        z-index: 99999;
        top: 20px;
        right: 0;
    }
    .mega .dropdown.mega-menu .dropdown-menu {
        margin: 0;
    }
    .mega-menu-text {
        padding: 15px 15px 15px 15px;
    }
    .main-menu .nav >li >a {
        padding: 0 20px;
        display: block;
        line-height: 45px;
    }
    .page-title {
        top: 0px;
        padding-top: 50px;
        margin-bottom: 0px;
    }
    .counting-item {
        margin-bottom: 50px;
    }
    .counting-item {
        text-align: center;
    }
    .counting-item .counting-icon {
        margin-bottom: 20px;
    }
    .nav-tabs>li {
        float: none !important;
    }
    .industries-tab .nav-tabs li a i {
        left: 15px;
    }
    .testimonial2-carousel .testimonial-message {
        font-size: 15px;
    }
    .industry-item .industry-info {
        margin-left: 15px;
    }
    .project-item .project-item-img {
        float: none;
        width: 100%;
        padding: 15px;
    }
    .project-item .project-item-info {
        float: none;
        width: 100%;
        padding: 15px;
    }
    .project-content {
        padding: 20px 15px;
    }
    .project-item {
        display: block;
    }
    .project-item .project-item-img {
        display: block;
    }
    .project-content {
        padding-left: 0;
    }
    .industries-photo img {
        display: none;
        border-radius: 50px 30px;
        background: #fff;
        box-shadow: 0 4px 32px 0 rgba(10,14,29,.02), 0 8px 64px 0 rgba(10,14,29,.08);
    }
    .team-item .team-img {
        float: none;
        width: 100%;
        padding: 15px;
    }
    .team-item .team-info {
        float: none;
        width: 100%;
        padding: 15px;
    }
    .clients-img {
        display: none;
    }
    .clients-logo a img {
        margin: 10px 0;
        max-width: 100%;
    }
    .cta-btn {
        margin-top: 25px;
    }
    .chart-progress {
        margin-bottom: 40px;
    }
    .service-message {
        margin-top: 30px;
    }
    .service-horizontal-tabs .nav {
        width: 100%;
        float: none;
        margin-bottom: 30px;
    }
    .service-horizontal-tabs .tab-content {
        width: 100%;
        float: none;
    }
    .service-info a.service-btn {
        width: 100%;
        margin-top: 25px;
    }
    .feature {
        top: 0;
        margin-bottom: 0x;
        margin-top: 50px;
    }
    .slider {
        top: 0px;
        margin-bottom: 0px;
    }
    .slider-btn {
        font-size: 12px;
        line-height: 12px;
        padding: 5px 20px;
    }
    .tp-caption {
        padding-left: 15px;
    }
    .rev_slider .tp-caption, 
    .rev_slider .caption {
        padding-left: 35px!important;
    }

    .price-item {
        margin-bottom: 50px;
    }
    .pricing2-item {
        margin-bottom: 130px;
    }
    .industry-item .industry-info {
        margin-right: 15px;
        margin-left: 15px;
    }
    .blog-header ul {
        line-height: 20px;
    }
    .contact-item {
        margin-top: 40px;
    }
}
@media (min-width: 300px) {
    .topbar {
        display: none;
    }
    .logo-img {
        padding-top: 26px;
    }
    .navbar-offcanvas ul li {
        border-bottom: 1px dotted #e8e8e8;
    }
    .nav .dropdown .dropdown-menu li > a {
        border-bottom: 0;
        padding-left: 15px;
    }
    .main-menu {
        position: absolute;
        width: auto;
        float: left;
        border: 0;
        background: none;
        padding-right: 0;
        padding-left: 0;
        z-index: 9999;
        top: 20px;
        right: 0;
        z-index: 99999;
    }
    .mega .dropdown.mega-menu .dropdown-menu {
        margin: 0;
    }
    .mega-menu-text {
        padding: 15px 15px 15px 15px;
    }
    .main-menu .nav >li >a {
        padding: 0 20px;
        display: block;
        line-height: 45px;
    }
    .page-title {
        top: 0px;
        padding-top: 50px;
        margin-bottom: 0px;
    }
    .counting-item {
        margin-bottom: 50px;
    }
    .counting-item {
        text-align: center;
    }
    .counting-item .counting-icon {
        margin-bottom: 20px;
    }
    .nav-tabs>li {
        float: none !important;
    }
    .industries-tab .nav-tabs li a i {
        left: 15px;
    }
    .project-item .project-item-img {
        float: none;
        width: 100%;
        padding: 15px;
    }
    .project-item .project-item-info {
        float: none;
        width: 100%;
        padding: 15px;
    }
    .project-content {
        padding: 20px 15px;
    }
    .team-item .team-img {
        float: none;
        width: 100%;
        padding: 15px;
    }
    .team-item .team-info {
        float: none;
        width: 100%;
        padding: 15px;
    }
    .clients-img {
        display: none;
    }
    .clients-logo a img {
        margin: 10px 0;
        max-width: 100%;
    }
    .cta-btn {
        margin-top: 25px;
    }
    .chart-progress {
        margin-bottom: 40px;
    }
    .service-message {
        margin-top: 30px;
    }
    .service-horizontal-tabs .nav {
        width: 100%;
        float: none;
        margin-bottom: 30px;
    }
    .service-horizontal-tabs .tab-content {
        width: 100%;
        float: none;
    }
    .service-info a.service-btn {
        width: 100%;
        margin-top: 25px;
    }
    .feature {
        top: 0;
        margin-bottom: 0x;
        margin-top: 50px;
    }
    .slider {
        top: 0px;
        margin-bottom: 0px;
    }
    .slider-btn {
        font-size: 12px;
        line-height: 12px;
        padding: 5px 20px;
    }
    .tp-caption {
        padding-left: 15px;
    }
    .price-item {
        margin-bottom: 50px;
    }
    .pricing2-item {
        margin-bottom: 130px;
    }
    .industry-item .industry-info {
        margin-right: 15px;
        margin-left: 15px;
    }
    .blog-header ul {
        line-height: 20px;
    }
    .contact-item {
        margin-top: 40px;
    }
}
@media (min-width: 360px) {
    .clients-logo a img {
        margin: 10px auto;
        max-width: 100%;
    }
}

@media (max-width: 425px) {
    .blog-header h2 {
        font-size: 18px;
    }
}


@media (min-width: 640px) {
    .h3, h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .section-title {
        margin-bottom: 30px;
    }
    .slider-btn {
        font-size: 13px;
        line-height: 18px;
        padding: 8px 36px;
    }
    .testimonial2-carousel .testimonial-message {
        font-size: 15px;
    }
}

@media (max-width: 992px) {
    .nav > li > ul.dropdown-menu {
        padding: 0 15px;
    }
    .navbar-offcanvas ul li {
        border-bottom: 0;
    }
}

@media (max-width: 990px) {
    .navbar-offcanvas .dropdown-menu {
        left: 0 !important;
    }
    .navbar-offcanvas .dropdown-menu .dropdown-menu {
        padding-left: 0;
        display: none;
    }
    .navbar-offcanvas .active > .dropdown-menu {
        display: block !important;
        transition: none !important;
    }
    .mega-menu-item li a {
        margin: 0 -15px;
    }
    .mega-menu-text {
        padding: 15px 15px 15px 0;
    }
    .nav > li > ul.dropdown-menu {
        padding: 0 15px; 
    }
    .nav > li > ul.dropdown-menu > li > ul.dropdown-menu {
        padding: 0;
    }
    .navbar-offcanvas .dropdown.active > a > .caret {
        border-top: 0;
        border-bottom: 4px solid;
    }
    .navbar-offcanvas .dropdown .caret,
    .navbar-offcanvas .dropdown.active .caret {
        float: right;
        margin-top: 17px;
    }
    .navbar-offcanvas .dropdown .dropdown .caret,
    .navbar-offcanvas .dropdown.active .dropdown .caret {
        margin-top: 6px;
    }
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-toggle {
        display: block;
        margin: auto;
        width: 51%;
    }
    .comment-list .author-response.odd {
        padding-left: 30px;
    }
    .industries-photo {
        margin-top: 30px;
        margin-bottom: 40px;
    }
    article .post-quote {
	    padding: 20px 20px 0px 20px;
	}
}

@media (max-width: 767px) {
    .navbar-toggle {
        display: block;
        margin: 10px;
        width: auto;
    }
}
@media (min-width: 991px) {
    .nav li:hover > ul {
        display: block;
    }
    .nav ul li:hover > ul {
        display: block;
    }
    .nav li:hover > ul {
        display: block;
        -moz-animation: zoomIn .3s ease-in;
        -webkit-animation: zoomIn .3s ease-in;
        animation: zoomIn .3s ease-in;
    }
    .navbar-offcanvas ul li {
        border-bottom: 0;
    }
    .nav .dropdown .dropdown-menu li > a {
        border-bottom: 1px solid #f5f5f5;
        padding-left: 20px;
    }
    .nav > li > ul li .caret {
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 4px solid transparent;
        border-left: 4px solid;
    }
    .mega .dropdown.mega-menu .dropdown-menu {
        margin: 0 -25.5px;
    }
    .mega-menu-text {
        padding: 15px 15px 15px 25px;
    }
    .main-menu .nav >li >a {
        padding: 0 10px;
    }
    .navbar-toggle {
        display: none;
    }
}

@media (min-width: 768px) {
    .section-title:after {
        content: '';
        position: absolute;
        width: 1000px;
        height: 3px;
        background: #8e8d92;
        top: 44%;
        left: -1000px;
    }
    .section-padding {
        margin: 0px 0px;
        padding: 70px 0 60px 0px;
    }
    .back-top span {
        width: 50px;
        height: 50px;
        padding-top: 10px;
    }
    .topbar {
        display: block;
    }
    .topbar:after {
        left: 20%;
    }
    .logo-img {
        vertical-align: middle;
        display: table-cell;
        padding-top: 0px;
    }
    .main-menu {
        position: relative;
        padding: 15px 25px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #f5f5f5;
        z-index: 9999;
        top: 0px;
        border: 0;
        padding: 0;
    }
    .page-title {
        top: -36px;
        padding-top: 100px;
        margin-bottom: -45px;
    }
    .counting-item {
        margin-bottom: 0;
    }
    .project-item {
        display: block;
    }
    .project-item .project-item-img {
        float: left;
        width: 100%;
        padding: 15px;
    }
    .project-item .project-item-info {
        float: left;
        width: 100%;
        padding: 15px;
    }
    .project-item .project-item-img a img {
        margin-bottom: 0;
    }
    .project-content {
        padding-top: 40px;
        padding-right: 15px;
    }
    .industries-photo {
        margin-top: 0;
        margin-bottom: 0px;
    }
    .testimonial2-carousel .testimonial-message {
        font-size: 18px;
    }
    .team-item .team-img {
        float: left;
        width: 100%;
        padding: 0px;
    }
    .team-item .team-info {
        float: left;
        width: 100%;
        padding: 15px;
        padding-left: 30px;
    }
    .clients-logo .col-md-4 {
        border-right: 1px solid #fff;
        padding: 30px;
    }
    .clients-logo:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: #fff;
        left: 0;
        top: 50%;
    }
    .cta-btn {
        margin-top: 0px;
    }
    .service-horizontal-tabs .nav {
        width: 46%;
        float: left;
        margin-bottom: 0px;
    }
    .service-horizontal-tabs .tab-content {
        width: 50%;
        float: right;
    }
    .service-info a.service-btn {
        margin-top: 47px;
        width: 46%;
        margin-top: 65px;
    }
    .rev_slider_wrapper .large_text h3 {
        line-height: 30px;
        font-size: 38px;
    }
    .tp-caption {
        padding-left: 0px;
    }
    .price-item,
    .pricing2-item {
        margin-bottom: 50px;
    }
    .industry-item .industry-info {
        margin-right: 15px;
        margin-left: -15px;
    }
    .blog-header ul {
        line-height: 15px;
    }
    .comment-list .author-response.odd {
        padding-left: 85px;
    }
    article .post-quote {
	    padding: 60px 50px 40px;
	}
}

@media (min-width: 769px) {
    .project-item .project-item-info {
        float: left;
        width: 100%;
        padding: 15px;
    }
    .project-item .project-item-img {
        float: none; 
        width: 48%;
        padding: 0px;
        display: table-cell;
        vertical-align: middle;
    }
    .team-item .team-img {
        float: none;
        width: 48%;
        padding: 0;
        display: table-cell;
        vertical-align: middle;
    }

    .project-item .project-item-info {
        float: none; 
        display: table-cell;
        padding: 15px;
        padding-left: 30px;
        vertical-align: middle;
    }
    .team-item .team-info {
        float: none;
         width: auto; 
        padding: 15px;
        padding-left: 30px;
        display: table-cell;
        vertical-align: middle;
    }

}

@media (min-width: 992px) { 
    .mega .dropdown.mega-menu .dropdown-menu {
        width: 940px;
    }
    .main-menu {
        border: 1px solid #f5f5f5;
        padding: 0 25px;
    }
    .service-info a.service-btn {
        margin-top: 65px;
    }
    .projects-article .img-thumb {
        padding-top: 40px;
    }
}

@media (min-width: 1024px) {
    .topbar:after {
        left: 40%;
    }
    .nav-tabs>li {
        float: left !important;
    }
    .industries-tab .nav-tabs li a i {
        left: 42%;
    }
    .clients-img {
        display: block;
    }
    .chart-progress {
        margin-bottom: 0px;
    }
    .service-message {
        margin-top: 0px;
    }
    .feature {
        /*top: -114px;*/
        /*margin-bottom: -114px;*/
        /*margin-top: 0px;*/
        margin: 30px auto 0;
    }
    .rev_slider_wrapper .large_text h3 {
        line-height: 55px;
        font-size: 50px;
    }
    .slider {
        top: -38px;
        margin-bottom: -38px;
    }
    .main-menu .nav >li >a {
        line-height: 70px;
    }
    .mega-menu-text {
        padding: 15px 15px 15px 0;
    }
    .price-item,
    .pricing2-item {
        margin-bottom: 0px;
    }
    .contact-item {
        margin-top: 0px;
    }
}
@media (min-width: 1199px) {
    .mega .dropdown.mega-menu .dropdown-menu {
        margin: 0 -21px;
        width: 1140px;
    }
    .main-menu {
        padding: 0 20px;
        border-radius: 15px;
        box-shadow: 0 4px 32px 0 rgba(10,14,29,.02), 0 8px 64px 0 rgba(10,14,29,.08);

    }
    .counting-item {
        text-align: left;
    }
    .counting-item .counting-icon {
        float: left;
    }
    .counting-item .counting-content {
        float: left;
    }
    .rev_slider_wrapper .large_text h3 {
        line-height: 61px;
        font-size: 56px;
    }
    .main-menu .nav >li >a {
        padding: 0 15px;
    }
    .projects-article .img-thumb {
        padding-top: 0px;
    }
}

@media (min-width: 1200px) {
    .industries-photo img {
        display: block;
    }
    .industries2-feature {
        margin-top: 50px;
    }
    .rev_slider .tp-caption, 
    .rev_slider .caption {
        padding-left: 0 !important;
    }
}








